function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { defineComponent, reactive, ref, toRefs } from 'vue';
import { POST_FROM_LOCATION_WORKER_EVENT } from 'sql/workers/events';
import { wrap } from 'vue/webComponentWrap';
import PresentationMode from "./PresentationMode.vue";
import SubscriptionTracker from 'components/utils/SubscriptionTracker';
var PresentationModeKoBridge = defineComponent({
  name: 'PresentationModeKoBridge',
  components: {
    PresentationMode: PresentationMode
  },
  props: {
    descriptionObservable: {
      type: Object,
      "default": undefined
    },
    executor: {
      type: Object,
      "default": null
    },
    initialVariables: {
      type: Object,
      "default": undefined
    },
    titleObservable: {
      type: Object,
      "default": undefined
    }
  },
  setup: function setup(props) {
    var subTracker = new SubscriptionTracker();
    var _toRefs = toRefs(props),
      descriptionObservable = _toRefs.descriptionObservable,
      titleObservable = _toRefs.titleObservable;
    var description = ref(null);
    var locations = reactive([]);
    var title = ref(null);
    subTracker.trackObservable(descriptionObservable, description);
    subTracker.trackObservable(titleObservable, title);
    subTracker.subscribe(POST_FROM_LOCATION_WORKER_EVENT, function (e) {
      if (e.data && e.data.locations) {
        locations.splice.apply(locations, [0, locations.length].concat(_toConsumableArray(e.data.locations)));
      }
    });
    return {
      description: description,
      locations: locations,
      title: title
    };
  },
  methods: {
    onBeforeExecute: function onBeforeExecute(executable) {
      this.$el.dispatchEvent(new CustomEvent('before-execute', {
        bubbles: true,
        detail: executable
      }));
    },
    onClose: function onClose() {
      this.$el.dispatchEvent(new CustomEvent('close', {
        bubbles: true
      }));
    },
    onVariablesChanged: function onVariablesChanged(variables) {
      this.$el.dispatchEvent(new CustomEvent('variables-changed', {
        bubbles: true,
        detail: variables
      }));
    }
  }
});
export var COMPONENT_NAME = 'presentation-mode-ko-bridge';
wrap(COMPONENT_NAME, PresentationModeKoBridge);
export default PresentationModeKoBridge;