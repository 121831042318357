function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { EXECUTABLE_LOGS_UPDATED_TOPIC, EXECUTABLE_UPDATED_TOPIC } from 'apps/editor/execution/events';
import { debounce } from 'lodash';
import { defineComponent, computed, onBeforeUnmount, ref, reactive } from 'vue';
import SqlExecutable, { ExecutionStatus } from 'apps/editor/execution/sqlExecutable';
import HueLink from "../../../../components/HueLink.vue";
import LogsPanel from "../../../../components/LogsPanel.vue";
import SubscriptionTracker from 'components/utils/SubscriptionTracker';
import I18n from "../../../../utils/i18n";
export default defineComponent({
  name: 'ExecutionAnalysisPanel',
  components: {
    HueLink: HueLink,
    LogsPanel: LogsPanel
  },
  props: {
    executable: {
      type: SqlExecutable,
      "default": undefined
    }
  },
  setup: function setup(props) {
    var subTracker = new SubscriptionTracker();
    onBeforeUnmount(subTracker.dispose.bind(subTracker));
    var analysisAvailable = ref(false);
    var executionLogs = ref('');
    var jobs = reactive([]);
    var errors = reactive([]);
    var jobsAvailable = computed(function () {
      return !!jobs.length;
    });
    var jobsWithUrls = computed(function () {
      return jobs.filter(function (job) {
        return job.url;
      });
    });
    var debouncedUpdate = debounce(function (executable) {
      var status = executable.status,
        logs = executable.logs;
      executionLogs.value = logs.fullLog;
      jobs.splice.apply(jobs, [0, jobs.length].concat(_toConsumableArray(logs.jobs)));
      errors.splice.apply(errors, [0, errors.length].concat(_toConsumableArray(logs.errors)));
      analysisAvailable.value = status !== ExecutionStatus.ready || !!errors.length;
    }, 5);
    var updateFromExecutable = function updateFromExecutable(executable) {
      if (!props.executable || props.executable.id !== executable.id) {
        return;
      }
      debouncedUpdate.cancel();
      debouncedUpdate(executable);
    };
    updateFromExecutable(props.executable);
    subTracker.subscribe(EXECUTABLE_UPDATED_TOPIC, updateFromExecutable);
    subTracker.subscribe(EXECUTABLE_LOGS_UPDATED_TOPIC, function (executionLogs) {
      updateFromExecutable(executionLogs.executable);
    });
    return {
      analysisAvailable: analysisAvailable,
      executionLogs: executionLogs,
      jobs: jobs,
      jobsAvailable: jobsAvailable,
      jobsWithUrls: jobsWithUrls,
      errors: errors,
      I18n: I18n
    };
  }
});